<template>
  <div
    class="m"
    :style="[{ 'border-bottom': val == 'yes' ? '1px solid #e3f3ff' : '0' }]"
  >
    <div class="box">
      <SvgIcon
        @click="touxiangBtn"
        iconClass="logo"
        style="font-size: 130px; cursor: pointer"
      ></SvgIcon>

      <div class="txt">
        <p>Need Assistance with your Order ?</p>
        <span>
          please email
          <a href="mailto:order@moominvalleypark-booking.com"
            >order@moominvalleypark-booking.com</a
          >
        </span>
      </div>
    </div>

    <!-- 询问框 -->
    <div class="the-query-box">
      <el-dialog
        :visible.sync="tishiLoginDialog"
        width="408px"
        :before-close="handleClose"
      >
        <span>Are you sure you want to leave this page?</span>
        <span slot="footer" class="dialog-footer">
          <el-button @click="yesClick">Leave</el-button>
          <el-button type="primary" @click="tishiLoginDialog = false"
            >Stay</el-button
          >
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import SvgIcon from "./SvgIcon.vue";
export default {
  data() {
    return {
      tishiLoginDialog: false, // 弹窗询问
    };
  },
  props: ["dlogBr"],
  methods: {
    // 头像点击事件
    touxiangBtn() {
      if (this.dlogBr) {
        this.tishiLoginDialog = true;
      } else {
        this.$router.push({
          path: "/",
        });
      }
    },
    // 询问框确定按钮
    async yesClick() {
      // console.log("yes");
      this.tishiLoginDialog = false;
      this.$router.push({
        path: "/",
      });
    },
    handleClose(done) {
      done();
    },
  },
  props: {
    val: String,
    dlogBr: Boolean,
  },
  components: {
    SvgIcon,
  },
};
</script>

<style lang="less" scoped>
.m {
  width: 100%;
  min-width: 1030px;
  height: 76px;
  background-color: #fff;

  .box {
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    width: 1030px;
    margin: 0 auto;
    padding: 0 15px;
    .txt {
      p {
        font-weight: 700;
      }

      a {
        font-weight: 700;
        color: #007bac;
      }
    }
  }
  .svg-icon {
    height: 100%;
  }
}

.the-query-box {
  /deep/.el-dialog__header {
    padding: 0;
  }
  /deep/.el-dialog__body {
    padding: 0;

    font-size: 24px;
    color: #12334c;
    margin-bottom: 35px;
    span {
      word-break: break-word;
    }
  }
  /deep/.el-dialog__footer {
    padding: 0;
  }
  /deep/.el-button {
    width: 159px;
    height: 42px;
    font-size: 16px;
  }
  /deep/.dialog-footer {
    width: 348px;
    display: flex;
    justify-content: space-between;
  }

  /deep/.el-dialog {
    padding: 40px 30px;
    border-radius: 12px;
    margin-top: 35vh !important;
  }
  /deep/.el-icon-close:before {
    content: "";
  }
  /deep/.el-button--default {
    border-radius: 7px;
    border: 1px solid #12334c;
    color: #12334c;
  }
  /deep/.el-button--primary {
    border-radius: 7px;
    border: 1px solid #12334c;
    color: #fff;
    background-color: #12334c;
  }
}
</style>